<template>
  <v-container
    fluid
  >
    <v-row>
      <v-col>
        <v-sheet
          color="white"
          class="pa-3"
          tile
          elevation="1"
        >
          <v-row>
            <v-col
              cols="6"
              md="2"
            >
              <v-menu
                v-model="menuDataInicio"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{}">
                  <v-text-field
                    v-model="dataInicioFormatada"
                    v-mask="'##/##/####'"
                    label="Data Início"
                    dense
                    hide-details="auto"
                    outlined
                    append-icon="mdi-calendar"
                    @blur="filtros.data_inicio = formatarData.api(dataInicioFormatada)"
                    @click:append="menuDataInicio = true"
                  />
                </template>
                <v-date-picker
                  v-model="filtros.data_inicio"
                  no-title
                  class="ma-0"
                  @input="menuDataInicio = false"
                />
              </v-menu>
            </v-col>
            <v-col
              cols="6"
              md="2"
              class="pl-md-0"
            >
              <v-menu
                v-model="menuDataFim"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{}">
                  <v-text-field
                    v-model="dataFimFormatada"
                    v-mask="'##/##/####'"
                    label="Data Fim"
                    dense
                    hide-details="auto"
                    outlined
                    append-icon="mdi-calendar"
                    @blur="filtros.data_fim = formatarData.api(dataFimFormatada)"
                    @click:append="menuDataFim = true"
                  />
                </template>
                <v-date-picker
                  v-model="filtros.data_fim"
                  no-title
                  class="ma-0"
                  @input="menuDataFim = false"
                />
              </v-menu>
            </v-col>
            <v-col
              cols="6"
              class="ml-md-2 pt-0 pl-md-0 pt-md-2"
            >
              <v-btn
                color="info"
                class="mt-1"
                :loading="carregando"
                @click="buscarLeituras()"
              >
                <v-icon
                  left
                  v-text="'mdi-magnify'"
                />
                Buscar
              </v-btn>
            </v-col>
          </v-row>
        </v-sheet>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-sheet elevation="1">
          <v-data-table
            disable-filtering
            class="placasCorrigidas"
            disable-sort
            :server-items-length="totalRegistros"
            :loading="carregando"
            :headers="cabecalho"
            :items="linhas"
            :options.sync="paginacao"
            :footer-props="{
              'items-per-page-options': [10, 20, 50]
            }"
            @update:options="buscarLeituras"
          >
            <template v-slot:[`item.data_correcao`]="{ item }">
              {{ item.data_correcao | data('DD/MM/YYYY HH:mm') }}
            </template>

            <template v-slot:[`item.imagem`]="{ item }">
              <div class="tables">
                <v-img
                  :src="item.imagem_leitura ? `data:image/png;base64,${item.imagem_leitura}` : $store.state.layout.imagemSemRecorte"
                  class="recorte-placa"
                  @click="buscarDetalhesLeitura(item.leitura_id)"
                >
                  <v-progress-linear
                    v-if="carregandoDetalhesLeitura === item.leitura_id"
                    height="2"
                    indeterminate
                    color="primary"
                  />
                </v-img>
              </div>
            </template>
          </v-data-table>
        </v-sheet>
      </v-col>
    </v-row>

    <dialog-detalhes-leitura
      v-if="mostrarDialogLeitura"
      v-model="mostrarDialogLeitura"
      :leitura="detalhesLeitura"
    />
  </v-container>
</template>

<script>
  import apiRelatorios from '@/api/relatorios'
  import apiLeituras from '@/api/leituras'
  import formatarData from '@/utils/formatar-data-input'
  import dayjs from 'dayjs'

  export default {

    components: {
      DialogDetalhesLeitura: () => import('@/components/dialog/leitura/DialogDetalhesLeitura'),
    },

    data () {
      return {
        linhas: [],
        carregando: false,
        carregandoDetalhesLeitura: false,
        menuDataInicio: false,
        menuDataFim: false,
        dataInicioFormatada: null,
        dataFimFormatada: null,
        filtros: {
          data_inicio: dayjs().startOf('month').format('YYYY-MM-DD'),
          data_fim: dayjs().endOf('month').format('YYYY-MM-DD'),
        },
        formatarData,
        cabecalho: [
          { text: 'Data', value: 'data_correcao'},
          { text: 'Placa Nova', value: 'placa_nova' },
          { text: 'Placa Antiga', value: 'placa_antiga' },
          { text: 'Usuário Responsável', value: 'usuario.pessoa.nome' },
          { text: 'Placa Modificada', value: 'placa_modificada_tipo' },
          { text: 'Imagem', value: 'imagem', align: 'right' },
        ],
        paginacao: {},
        totalRegistros: null,
        mostrarDialogLeitura: false,
        detalhesLeitura: null,
      }
    },

    watch: {
      'filtros.data_inicio': {
        immediate: true,
        handler (data) {
          this.dataInicioFormatada = formatarData.ptBr(data)
        },
      },
      'filtros.data_fim': {
        immediate: true,
        handler (data) {
          this.dataFimFormatada = formatarData.ptBr(data)
        },
      },
    },

    methods: {

      async buscarLeituras () {
        try {
          this.carregando = true

          const parametros = { ...this.filtros }

          parametros.por_pagina = this.paginacao.itemsPerPage
          parametros.pagina = this.paginacao.page

          const dados = await apiRelatorios.leiturasPlacaCorrigida(parametros)
          console.log(dados, 'dados')

          this.linhas = dados.data.data
          this.linhas.map((item) => {
            if (item.placa_modificada === 1) {
              item.placa_modificada_tipo = 'Automaticamente'
            } else if (item.placa_modificada === 2) {
              item.placa_modificada_tipo = 'Manualmente'
            }
          })
          this.totalPaginas = dados.data.meta.last_page
          this.totalRegistros = dados.data.meta.total
        } catch (e) {
          this.$snackbar.mostrar({ cor: 'error', mensagem: this.$erroApi._(e) })
        } finally {
          this.carregando = false
        }
      },

      async buscarDetalhesLeitura (leituraId) {
        try {
          this.carregandoDetalhesLeitura = leituraId
          const dados = await apiLeituras.detalhes(leituraId)

          this.detalhesLeitura = dados.data
          this.mostrarDialogLeitura = true
        } catch (e) {
          this.$snackbar.mostrar({ cor: 'error', mensagem: this.$erroApi._(e) })
        } finally {
          this.carregandoDetalhesLeitura = false
        }
      },
    },
  }
</script>

<style>
.tables {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
}
  .recorte-placa {
    width: 100%;
    cursor: pointer;
  }
.placasCorrigidas > .v-data-table__wrapper > table > tbody > tr > td:first-child {
  font-size: 13px;
}

  @media screen and (min-width: 768px) {
    .recorte-placa {
      width: 45% !important;
    }
  }
</style>
